export default [
  {
    component: 'CNavItem',
    name: 'Oversikt',
    to: '/oversikt',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Rapport',
    to: '/rapport',
    icon: 'cil-chartPie',
  },
  {
    component: 'CNavItem',
    name: 'Planlegger',
    to: '/planner',
    icon: 'cil-truck',
  },
  {
    component: 'CNavTitle',
    name: 'Administrasjon',
  },
  {
    component: 'CNavItem',
    name: 'Kunder',
    to: '/kunder/',
    icon: 'cilPeople',
  },
  {
    component: 'CNavItem',
    name: 'Produkter',
    to: '/produkter/',
    icon: 'cilList',
  },
  { 
    component: 'CNavItem',
    name: 'Lagerstyring',
    to: '/lager/',
    icon: 'cilBalanceScale',
  },
  {
    component: 'CNavItem',
    name: 'Fakturering',
    to: '/fakturaunderlag/',
    icon: 'cilCalculator',
  },
  {
    component: 'CNavItem',
    name: 'Administrasjon',
    to: '/administrasjon/',
    icon: 'cilLockUnlocked',
    admin: true,
  },
  {
    component: 'CNavTitle',
    name: 'Varehåndtering',
  },
  {
    component: 'CNavItem',
    name: 'Fyllinger',
    to: '/fyllinger/',
    icon: 'cilArrowThickToBottom',
  },
  {
    component: 'CNavItem',
    name: 'Leveringer',
    to: '/leveringer/',
    icon: 'cilLoop',
  },
  {
    component: 'CNavItem',
    name: 'Bestillinger',
    to: '/bestillinger/',
    icon: 'cilPhone',
  },
  

  // 
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
