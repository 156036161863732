<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CIcon icon="cilArrowLeft" @click="goBack" class="backBtn" size="lg" /> 
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  methods: {
    goBack: function() {
      this.$router.go(-1)
    }
  },
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    

    return {
      breadcrumbs,
    }
  },
}
</script>

<style>
.backBtn {
    margin-right: 10px;
    top: 3px;
    position: relative;
    background: #222d5a;
    color: white;
    border-radius: 50px;
    padding: 3px;
}
</style>