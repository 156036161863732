export const logoNegative = [
  '556 134',
  `
  <title>Byttgass logo</title>
  <svg width="100%" height="100%" viewBox="0 0 504 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g transform="matrix(0.954591,0,0,0.954591,11.4242,1.39273)">
          <g transform="matrix(1,0,0,0.265921,-80.5591,-26.6063)">
              <path d="M135.025,308.59L135.025,122.195L151.23,122.195C156.608,122.195 160.654,126.377 163.366,134.741C166.078,143.105 167.434,155.48 167.434,171.866C167.434,180.572 166.776,188.274 165.46,194.974C164.143,201.673 162.35,206.858 160.081,210.528C162.759,213.344 164.875,218.7 166.43,226.594C167.984,234.489 168.762,243.898 168.762,254.823C168.762,271.55 167.321,284.694 164.438,294.252C161.556,303.811 157.482,308.59 152.217,308.59L135.025,308.59ZM141.561,221.41L141.561,288.492L152.353,288.492C155.394,288.492 157.794,285.526 159.553,279.594C161.312,273.663 162.191,265.491 162.191,255.079C162.191,232.633 158.946,221.41 152.455,221.41L141.561,221.41ZM141.561,201.695L151.434,201.695C154.294,201.695 156.58,199.006 158.294,193.629C160.007,188.253 160.864,180.956 160.864,171.738C160.864,161.497 160.069,154.05 158.481,149.399C156.892,144.748 154.475,142.422 151.23,142.422L141.561,142.422L141.561,201.695Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M191.678,215.777L204.615,122.195L212.036,122.195L194.946,239.076L194.946,308.59L188.41,308.59L188.41,239.076L171.321,122.195L178.81,122.195L191.678,215.777Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M253.268,142.422L237.335,142.422L237.335,308.59L230.833,308.59L230.833,142.422L214.935,142.422L214.935,122.195L253.268,122.195L253.268,142.422Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M295.418,142.422L279.486,142.422L279.486,308.59L272.984,308.59L272.984,142.422L257.086,142.422L257.086,122.195L295.418,122.195L295.418,142.422Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M338.42,284.139C336.741,293.186 334.397,299.949 331.39,304.43C328.383,308.911 324.882,311.151 320.888,311.151C316.848,311.151 313.262,307.588 310.13,300.461C306.999,293.335 304.576,283.2 302.862,270.057C301.149,256.913 300.269,241.679 300.224,224.354L300.224,208.096C300.224,180.017 301.966,158.254 305.45,142.806C308.933,127.358 313.83,119.634 320.139,119.634C325.314,119.634 329.478,124.606 332.633,134.549C335.787,144.492 337.717,158.595 338.42,176.859L331.884,176.859C330.658,152.194 326.755,139.861 320.173,139.861C315.793,139.861 312.474,145.644 310.216,157.208C307.957,168.772 306.817,185.522 306.794,207.456L306.794,222.69C306.794,243.6 308.065,260.221 310.607,272.553C313.149,284.886 316.587,291.052 320.922,291.052C323.373,291.052 325.518,290.028 327.356,287.979C329.195,285.931 330.715,282.475 331.918,277.61L331.918,235.748L320.445,235.748L320.445,215.777L338.42,215.777L338.42,284.139Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M376.69,259.943L355.924,259.943L351.26,308.59L344.519,308.59L363.447,122.195L369.167,122.195L388.128,308.59L381.422,308.59L376.69,259.943ZM357.898,239.716L374.75,239.716L366.307,152.535L357.898,239.716Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M409.411,225.506C403.805,219.447 399.726,212 397.172,203.167C394.619,194.334 393.343,183.431 393.343,170.458C393.343,155.779 394.903,143.638 398.024,134.037C401.144,124.435 405.201,119.634 410.194,119.634C413.598,119.634 416.634,122.11 419.3,127.06C421.967,132.01 424.032,138.837 425.496,147.543C426.96,156.248 427.692,165.764 427.692,176.091L421.122,176.091C421.122,164.825 420.168,155.971 418.262,149.527C416.356,143.083 413.666,139.861 410.194,139.861C406.971,139.861 404.458,142.529 402.653,147.863C400.849,153.197 399.947,160.601 399.947,170.074C399.947,177.67 400.804,184.092 402.517,189.341C404.231,194.59 407.147,199.39 411.266,203.743C415.385,208.096 418.608,212.896 420.934,218.145C423.261,223.394 424.986,229.517 426.109,236.516C427.232,243.514 427.794,251.75 427.794,261.223C427.794,276.33 426.228,288.428 423.096,297.517C419.964,306.606 415.777,311.151 410.534,311.151C407.13,311.151 403.953,308.697 401.002,303.79C398.052,298.882 395.777,292.161 394.177,283.627C392.577,275.092 391.777,265.405 391.777,254.566L398.347,254.566C398.347,265.832 399.453,274.729 401.666,281.258C403.879,287.787 406.835,291.052 410.534,291.052C413.984,291.052 416.628,288.406 418.466,283.115C420.305,277.823 421.224,270.612 421.224,261.48C421.224,252.347 420.373,245.285 418.671,240.292C416.968,235.3 413.882,230.371 409.411,225.506Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M450.779,225.506C445.173,219.447 441.093,212 438.54,203.167C435.987,194.334 434.71,183.431 434.71,170.458C434.71,155.779 436.271,143.638 439.391,134.037C442.512,124.435 446.569,119.634 451.562,119.634C454.966,119.634 458.001,122.11 460.668,127.06C463.335,132.01 465.4,138.837 466.864,147.543C468.328,156.248 469.06,165.764 469.06,176.091L462.489,176.091C462.489,164.825 461.536,155.971 459.63,149.527C457.723,143.083 455.034,139.861 451.562,139.861C448.339,139.861 445.825,142.529 444.021,147.863C442.217,153.197 441.315,160.601 441.315,170.074C441.315,177.67 442.172,184.092 443.885,189.341C445.598,194.59 448.515,199.39 452.634,203.743C456.753,208.096 459.976,212.896 462.302,218.145C464.628,223.394 466.353,229.517 467.477,236.516C468.6,243.514 469.162,251.75 469.162,261.223C469.162,276.33 467.596,288.428 464.464,297.517C461.332,306.606 457.145,311.151 451.902,311.151C448.498,311.151 445.32,308.697 442.37,303.79C439.42,298.882 437.145,292.161 435.544,283.627C433.944,275.092 433.144,265.405 433.144,254.566L439.715,254.566C439.715,265.832 440.821,274.729 443.034,281.258C445.247,287.787 448.203,291.052 451.902,291.052C455.352,291.052 457.996,288.406 459.834,283.115C461.672,277.823 462.592,270.612 462.592,261.48C462.592,252.347 461.74,245.285 460.038,240.292C458.336,235.3 455.25,230.371 450.779,225.506Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M476.691,296.173C476.691,292.076 477.014,288.662 477.661,285.931C478.308,283.2 479.278,281.835 480.572,281.835C481.866,281.835 482.847,283.2 483.517,285.931C484.186,288.662 484.521,292.076 484.521,296.173C484.521,300.099 484.186,303.384 483.517,306.03C482.847,308.676 481.866,309.999 480.572,309.999C479.278,309.999 478.308,308.676 477.661,306.03C477.014,303.384 476.691,300.099 476.691,296.173Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M533.991,308.59L527.421,308.59L502.467,164.953L502.467,308.59L495.897,308.59L495.897,122.195L502.467,122.195L527.489,266.472L527.489,122.195L533.991,122.195L533.991,308.59Z" style="fill:white;fill-rule:nonzero;"/>
              <path d="M583.733,221.41C583.733,239.674 582.916,255.612 581.282,269.225C579.648,282.837 577.333,293.228 574.337,300.397C571.342,307.566 567.846,311.151 563.852,311.151C559.949,311.151 556.488,307.545 553.469,300.333C550.451,293.122 548.107,282.837 546.439,269.481C544.771,256.124 543.914,240.655 543.869,223.074L543.869,209.632C543.869,191.709 544.697,175.877 546.354,162.137C548.011,148.396 550.354,137.877 553.384,130.58C556.414,123.283 559.88,119.634 563.784,119.634C567.756,119.634 571.256,123.24 574.286,130.452C577.316,137.664 579.648,148.119 581.282,161.817C582.916,175.515 583.733,191.453 583.733,209.632L583.733,221.41ZM577.231,209.376C577.231,187.271 576.051,170.309 573.691,158.488C571.33,146.668 568.028,140.758 563.784,140.758C559.654,140.758 556.402,146.668 554.031,158.488C551.659,170.309 550.439,186.716 550.371,207.712L550.371,221.41C550.371,242.831 551.568,259.666 553.963,271.913C556.357,284.16 559.654,290.284 563.852,290.284C568.073,290.284 571.342,284.502 573.656,272.937C575.971,261.373 577.163,244.794 577.231,223.202L577.231,209.376Z" style="fill:white;fill-rule:nonzero;"/>
          </g>
          <g transform="matrix(1,0,0,1,0.14775,43.3738)">
              <path d="M0,0.82C-0.197,0.582 -0.197,0.238 0,0.001C1.724,-2.076 9.579,-11.541 13.78,-16.603C14.186,-17.092 14.855,-17.274 15.453,-17.058C16.05,-16.842 16.449,-16.275 16.449,-15.639L16.449,16.46C16.449,17.095 16.05,17.663 15.453,17.879C14.855,18.094 14.186,17.912 13.78,17.423C9.579,12.362 1.724,2.897 0,0.82" style="fill:rgb(240,141,27);"/>
          </g>
          <g transform="matrix(1,0,0,1,16.4488,17.1478)">
              <path d="M0,0.82C0.197,0.582 0.197,0.238 0,0C-1.724,-2.076 -9.579,-11.541 -13.78,-16.603C-14.186,-17.092 -14.855,-17.274 -15.452,-17.058C-16.05,-16.842 -16.448,-16.275 -16.448,-15.64L-16.448,16.46C-16.448,17.095 -16.05,17.663 -15.452,17.878C-14.855,18.094 -14.186,17.912 -13.78,17.423C-9.579,12.361 -1.724,2.897 0,0.82" style="fill:white;"/>
          </g>
      </g>
  </svg>
`,
]
