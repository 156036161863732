import store from "@/store";
import axios from "axios";
import router from "@/router";
import jwt_decode from "jwt-decode";

class TokenService {
  
  getLocalAccessToken() {
    const user = store.state.account.userInfo;
    return user?.token;
  }
  updateLocalAccessToken(token) {

    var jwt_decoded = jwt_decode(token);
    var user = {
      token: token,
      userId: jwt_decoded.data.userId,
      userName: jwt_decoded.data.userName,
      userEmail: jwt_decoded.data.userEmail,
      userAdminLevel: jwt_decoded.data.userAdminLevel
    };

    store.commit("login", user);
    this.userStatus = "Bruker innlogget";
    console.log('Access token successfully updated')

  }

  logoutUser() {

    // Revoke cookie fra databasen og nuller ut cookien
    axios.post('/static/classes/logout.php', {
    })
    .catch(function(error) {
      console.log('Error', error);
    });

    // Logg ut fra Vuex Store, fjerner informasjon.
    store.commit('logout')

    // Redirect to login page
    router.push('/pages/login/');

  }
}
export default new TokenService();