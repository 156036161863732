<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="getImgUrl()" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        {{this.$store.state.account.userInfo.userName}}
      </CDropdownHeader>
      <CDropdownDivider />
      <CDropdownItem @click="logout">
        <CIcon icon="cil-lock-locked" /> Logg ut
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import TokenService from "@/services/token.service";
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      itemsCount: 42,
    }
  },
  methods: {
    getImgUrl() {
      let userId = this.$store.state.account.userInfo.userId
      try {
          return require('@/assets/images/avatars/' + userId + '.jpg')
      } catch {
          return require('@/assets/images/avatars/noprofile.jpg')
      }
    },
    logout: function () {
      TokenService.logoutUser();
    }
  }
}
</script>
