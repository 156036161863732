import { createStore } from 'vuex'
import account from './modules/account'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    isLoading: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    account,
  },
  plugins: [createPersistedState()]
})
