import router from "@/router";
import axios from "axios"; 
import TokenService from "./token.service";


const setup = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      //console.log('err')
      return Promise.reject(error);
    }
  );



  axios.interceptors.response.use(

    // Hvis response ok, gjør ingenting.
    (res) => {
      return res;
    },
    async (err) => {

      // Hvis Error
      const originalConfig = err.config;
      if (originalConfig.url !== "/pages/login" && err.response) {
        
        // Hvis response 401 Unauthorized - Access token er utgått.
        if (err.response.status === 401 && !originalConfig._retry) {

          originalConfig._retry = true;

          // Prøv og hent ny access token basert på refresh token i cookie
          try {

            const rs = await axios.post("/static/classes/refresh_token.php", {              
            });

            const accessToken  = rs.data;
            TokenService.updateLocalAccessToken(accessToken);
            return axios(originalConfig);

          } catch (_error) {

            // Hvis feil, enten cookie ikke eksisterer eller at refresh token er revoket fra databasen
            TokenService.logoutUser();
            router.push('/pages/login');
            return Promise.reject(_error);
          }


          
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;