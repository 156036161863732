import jwt_decode from 'jwt-decode';


const state = {
  userInfo: null,
};

const mutations = {
  logout(state) {
    state.userInfo = null;
  },
  login(state, userInfo) {

    state.userInfo = userInfo;
  }
};

const getters = {
  isLoggedIn(state) {
    return !!state.userInfo
  },
  isAdmin(state) {

    if (state.userInfo) {
      var decode = jwt_decode(state.userInfo.token)
      if (decode.data.userAdminLevel === '2') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  }
};

export default {
  namespaced: false,
  state,
  mutations,
  getters
}