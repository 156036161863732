// Kan endres til createWebHistory. Krever at serveren settes opp til å peke alle requester mot et endepunkt. Les mer i vue router. 
import { createRouter, createWebHashHistory } from 'vue-router'
import { h, resolveComponent } from 'vue'

import DefaultLayout from '@/layouts/DefaultLayout'

// Used for route guard
import store from '../store'


const routes = [
  {
    path: '/',
    name: 'Byttgass',
    component: DefaultLayout,
    meta: { requiresAuth: true },
    redirect: '/oversikt',
    children: [
      {
        path: '/oversikt',
        name: 'Oversikt',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
      },
      {
        path: '/rapport',
        name: 'Rapport',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ReportView.vue')
      },
      {
        path: '/planner',
        name: 'PlannerView',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/PlannerView.vue')
      },
      {
        path: '/produkter',
        name: 'Produkter',
        meta: { requiresAuth: true },
        component: () => import('@/views/Produkter.vue'),
      },
      {
        path: '/home',
        name: 'HomeView',
        meta: { requiresAuth: true },
        component: () => import('@/views/HomeView.vue'),
      },
      {
        path: '/lager',
        name: 'Lagerstyring',
        meta: { requiresAuth: true },
        component: () => import('@/views/StockView.vue'),
      },
      {
        path: '/fakturaunderlag',
        name: 'Fakturering',
        meta: { requiresAuth: true },
        component: () => import('@/views/InvoicesListView.vue'),
      },
      {
        path: '/administrasjon',
        name: 'Administrasjon',
        meta: { requiresAuth: true, admin: true },
        component: () => import('@/views/AdminView.vue'),
      },
      {
        path: '/bestillinger',
        name: 'Bestillinger',
        meta: { requiresAuth: true },
        component: () => import('@/views/OrdersView.vue'),
      },{

        // Hacky as fuck. 
        // Main route går som /kunder uten trailing slash. 
        // Default path hvis parameter ikke er satt redirecter så til kunder med trailing slash. 
        // Kunne ikke ha to like paths, det ble krøll.
        path: '/bestillinger',
        name: 'Bestillinger ', // Kan ikke ha samme navn på to routere. Derfor mellomrom.
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view')) // Ingen komponent, kunder viderefør child til et router-view element.
          },
        },
        children: [
          {
            // Hvis ikke ID er satt. Redirect til øverste parent med trailing slash.
            path: '',
            strict: true,
            redirect: '/bestillinger/',
            meta: { requiresAuth: true },

          },
          {
            path: ':id',
            name: 'Bestilling detaljer',
            meta: { requiresAuth: true },
            component: () => import('@/views/OrderDetailView.vue'),
          },
        ]
      },
      {
        path: '/kunder/',
        strict: false,
        name: 'Kunder',
        meta: { requiresAuth: true },
        component: () => import('@/views/Kunder.vue'),
        children: [
        ]
      },{

        // Hacky as fuck. 
        // Main route går som /kunder uten trailing slash. 
        // Default path hvis parameter ikke er satt redirecter så til kunder med trailing slash. 
        // Kunne ikke ha to like paths, det ble krøll.
        path: '/kunder',
        name: 'Kunder ', // Kan ikke ha samme navn på to routere. Derfor mellomrom.
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view')) // Ingen komponent, kunder viderefør child til et router-view element.
          },
        },
        children: [
          {
            // Hvis ikke ID er satt. Redirect til øverste parent med trailing slash.
            path: '',
            strict: true,
            redirect: '/kunder/',
            meta: { requiresAuth: true },

          },
          {
            path: ':id',
            name: 'Kunde detaljer',
            meta: { requiresAuth: true },
            component: () => import('@/views/KundeSingleView.vue'),
          },
        ]
      },
      {
        path: '/produkter',
        name: 'Produkter',
        component: () => import('@/views/Produkter.vue'),
        meta: { requiresAuth: true },
      },{
        path: '/produkter',
        name: 'Produkter ',
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          }
        },
        children: [
          {
            // Hvis ikke ID er satt. Redirect til øverste parent med trailing slash.
            path: '',
            strict: true,
            redirect: '/produkter/',
            meta: { requiresAuth: true },

          },
          {
            path: ':id',
            name: 'Produkt detaljer',
            meta: { requiresAuth: true },
            component: () => import('@/views/ProduktSingleView.vue'),
          },
        ]
      },
      {
        path: '/fyllinger/',
        name: 'Fyllinger',
        component: () => import('@/views/FillSessionsListView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/brukere',
        name: 'Brukere',
        component: () => import('@/views/UsersView.vue'),
        meta: { requiresAuth: true, admin: true },
      },
      {
        path: '/fyllinger',
        name: 'Fyllinger ',
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          }
        },
        children: [
          {
            // Hvis ikke ID er satt. Redirect til øverste parent med trailing slash.
            path: '',
            strict: true,
            redirect: '/fyllinger/',
            meta: { requiresAuth: true },

          },
          {
            path: ':id',
            name: 'Fylling detaljer',
            meta: { requiresAuth: true },
            component: () => import('@/views/FillSessionDetailsView.vue'),
          },
        ]
      },
      {
        path: '/leveringer',
        name: 'Leveringer',
        component: () => import('@/views/DeliveriesListView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/leveringer',
        name: 'Levering ',
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          }
        },
        children: [
          {
            // Hvis ikke ID er satt. Redirect til øverste parent med trailing slash.
            path: '',
            strict: true,
            redirect: '/leveringer/',
            meta: { requiresAuth: true },

          },
          {
            path: ':id',
            name: 'Leverings detaljer',
            meta: { requiresAuth: true },
            component: () => import('@/views/DeliveriesDetailView.vue'),
          },
        ]
      }
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404View',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500View',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Logg inn',
        component: () => import('@/views/pages/Login'),
      },
      // {
      //   path: 'register',
      //   name: 'Registrering',
      //   component: () => import('@/views/pages/Register'),
      // },
    ],
  },
  { path: '/:pathMatch(.*)*', name: '404PageView', component: () => import('@/views/pages/Page404'), },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {

  document.title = to.name
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isLoggedIn) {

    next('/pages/login');
    return;
  } else {
    if (to.matched.some((record) => record.meta.admin) && !store.getters.isAdmin) {
      next('/pages/404Page');
      return
    }
    next();
  }
})

export default router
