<template>
  <CFooter>
    <div>
      <a href="#" target="_blank">Flaskegass</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} - Versjon {{ version}}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      version: process.env.PACKAGE_VERSION
    }
  }
}
</script>
