import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';


import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'

import { iconsSet as icons } from '@/assets/icons'
import './registerServiceWorker'

import setupInterceptors from '@/services/setupInterceptors'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueLoading, {
  container: null,
  canCancel: false,
  loader: 'bars',
  backgroundColor: '#222D5A',
  color: '#F08D1B',
  opacity: 0.4,
  height: 170,
  width: 170,
})
app.config.globalProperties.$axios = axios
app.provide('icons', icons)
app.component('CIcon', CIcon)
setupInterceptors(store)

app.mount('#app')
