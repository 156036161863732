<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';

</style>

<script>
//import axios from 'axios'
//import router from '@/router'

export default {
    
    created: function() {

    // axios.interceptors.response.use(function (response) {
    //     return response;
    // }, function (error) {
    //     if (401 === error.response.status) {
    //         router.push('/pages/login');
    //         alert('hi')

    //     } else {
    //         alert('hi')
    //         return Promise.reject(error);
    //     }
    // });
    
    
    

    }
}
</script>